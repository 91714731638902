<template>
  <div id="notVerfied">
    <Verification title="Achtung!" :isVerified="false">
        Der Verifizierungscode war nicht korrekt.
    </Verification>
  </div>
</template>

<script>
import Verification from "../../components/SiteComponents/Verification";

export default {
  name: "NotVerified",
  components:{Verification}
}
</script>

<style scoped>

</style>